import keys from '../keys';

const defaultState = {
  language: 'ITA'
};

export default function languageReducer(state = defaultState.language, action) {
  switch (action.type) {
    case keys.SET_LANGUAGE:
      return action.language;
    default:
      return state;
  }
}

// Action Creator
export function setLanguage(language) {
  return {
    type: keys.SET_LANGUAGE,
    language
  };
}
