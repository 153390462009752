import React from 'react';
import { connect } from 'react-redux';
import { BaseButton } from '@jam3/react-ui';
// import debounce from 'lodash.debounce';
// import PropTypes from 'prop-types';
// import checkProps from '@jam3/react-check-extra-props';
import sanitazer from '../../util/sanitizer';
import { setLanguage } from '../../redux/modules/language';

class LanguageSwitcher extends React.PureComponent {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.language !== prevState.language) {
      return { language: nextProps.language };
    } else return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      language: this.props.language
    };
  }

  handleClickItalian = () => {
    this.props.setLanguage('ITA');
  };
  handleClickEnglish = () => {
    this.props.setLanguage('EN');
  };

  render() {
    return (
      <ul className="language-switcher">
        <li>
          <BaseButton
            className={`language-switcher__btn ${
              this.props.language === 'ITA' ? 'language-switcher__btn--active' : ''
            }`}
            onClick={this.handleClickItalian}
          >
            {sanitazer('Italiano')}
          </BaseButton>
        </li>
        <li>
          <BaseButton
            className={`language-switcher__btn ${this.props.language === 'EN' ? 'language-switcher__btn--active' : ''}`}
            onClick={this.handleClickEnglish}
          >
            {sanitazer('English')}
          </BaseButton>
        </li>
      </ul>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setLanguage: val => dispatch(setLanguage(val))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  undefined,
  { withRef: true }
)(LanguageSwitcher);
