import React, { Fragment, lazy, Suspense } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import checkProps from '@jam3/react-check-extra-props';

import './Pages.scss';

import routeKeys from '../../routes/keys';
import { getTransitionDuration } from '../../data/pages-transitions';

const Landing = lazy(() => import(/* webpackChunkName: "Landing" */ '../../pages/Landing/Landing'));
const Artwork = lazy(() => import(/* webpackChunkName: "Artwork" */ '../../pages/Artwork/Artwork'));
const About = lazy(() => import(/* webpackChunkName: "About" */ '../../pages/About/About'));
const Review = lazy(() => import(/* webpackChunkName: "Review" */ '../../pages/Review/Review'));
const Contacts = lazy(() => import(/* webpackChunkName: "Contacts" */ '../../pages/Contacts/Contacts'));
const Media = lazy(() => import(/* webpackChunkName: "Media" */ '../../pages/Media/Media'));
const Experiments = lazy(() => import(/* webpackChunkName: "Experiments" */ '../../pages/Experiments/Experiments'));
const NotFound = lazy(() => import('../../pages/NotFound/NotFound'));

const getFields = (collection, id) => {
  if (collection) {
    const results = collection.filter(page => page.slug === id)[0];
    return results;
  }
};

const Pages = ({ location, ...props }) => {
  return (
    <main className={classnames('Pages', props.className)} role="main">
      <TransitionGroup component={Fragment}>
        <Transition appear key={location.pathname} timeout={getTransitionDuration(location.pathname)}>
          {state => (
            <Suspense fallback={<div className="loading" />}>
              <Switch location={location}>
                <Route
                  exact
                  path={routeKeys.Landing}
                  render={() => (
                    <Landing
                      projects={props.siteData.projects ? props.siteData.projects : {}}
                      transitionState={state}
                      language={props.language}
                    />
                  )}
                />
                <Route
                  exact
                  path={routeKeys.Experiments}
                  render={() => (
                    <Experiments
                      pageData={props.siteData.hasOwnProperty('experiments') ? props.siteData.experiments : {}}
                      transitionState={state}
                      language={props.language}
                    />
                  )}
                />
                <Route
                  exact
                  path={routeKeys.Media}
                  render={() => (
                    <Media
                      pageData={props.siteData.hasOwnProperty('video') ? props.siteData.video : {}}
                      transitionState={state}
                      language={props.language}
                    />
                  )}
                />
                <Route
                  exact
                  path={routeKeys.Contacts}
                  render={() => (
                    <Contacts
                      pageData={props.siteData.hasOwnProperty('contacts') ? props.siteData.contacts : {}}
                      transitionState={state}
                      language={props.language}
                    />
                  )}
                />
                <Route
                  exact
                  path={routeKeys.About}
                  render={() => (
                    <About
                      pageData={props.siteData.hasOwnProperty('about') ? props.siteData.about : {}}
                      transitionState={state}
                      language={props.language}
                    />
                  )}
                />
                <Route
                  exact
                  path={routeKeys.Review}
                  render={() => (
                    <Review
                      pageData={props.siteData.hasOwnProperty('review') ? props.siteData.review : {}}
                      transitionState={state}
                      language={props.language}
                    />
                  )}
                />
                {props.siteData.projects &&
                  props.siteData.projects.map((el, i) => {
                    const path = el.slug;
                    return (
                      <Route
                        exact
                        path={`/${path}`}
                        key={i}
                        render={() => (
                          <Artwork
                            pageData={getFields(props.siteData.projects, el.slug)}
                            transitionState={state}
                            language={props.language}
                          />
                        )}
                      />
                    );
                  })}
                <Route render={() => <NotFound />} />
              </Switch>
            </Suspense>
          )}
        </Transition>
      </TransitionGroup>
    </main>
  );
};

Pages.propTypes = checkProps({
  className: PropTypes.string,
  siteData: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired
});

Pages.defaultProps = {};

export default withRouter(Pages);
