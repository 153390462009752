import routeKeys from '../routes/keys';

export default {
  links_ita: [
    {
      text: 'Opere',
      path: routeKeys.Landing
    },
    {
      text: 'Biografia',
      path: routeKeys.About
    },
    {
      text: 'Recensione',
      path: routeKeys.Review
    },
    {
      text: 'Esperimenti',
      path: routeKeys.Experiments
    },
    {
      text: 'Video',
      path: routeKeys.Media
    },
    {
      text: 'Contatti',
      path: routeKeys.Contacts
    }
  ],
  links_en: [
    {
      text: 'Artworks',
      path: routeKeys.Landing
    },
    {
      text: 'Biography',
      path: routeKeys.About
    },
    {
      text: 'Review',
      path: routeKeys.Review
    },
    {
      text: 'Experiments',
      path: routeKeys.Experiments
    },
    {
      text: 'Video',
      path: routeKeys.Media
    },
    {
      text: 'Contacts',
      path: routeKeys.Contacts
    }
  ]
};
