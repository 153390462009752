import routeKeys from '../routes/keys';
import logo from '../assets/images/logo.png';

export default {
  logoSrc: logo,
  links: [
    {
      text: 'Opere',
      path: routeKeys.Landing
    },
    {
      text: 'Biografia',
      path: routeKeys.About
    },
    {
      text: 'Recensione',
      path: routeKeys.Review
    },
    {
      text: 'Esperimenti',
      path: routeKeys.Experiments
    },
    {
      text: 'Video',
      path: routeKeys.Media
    },
    {
      text: 'Contatti',
      path: routeKeys.Contacts
    }
  ]
};
